import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import bannerBack from "../../../images/svg/banner-back.svg";

const BannerContainer = styled.div`
  min-height: calc(100vh - 56px);
`;

export const BannerDois = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "contact.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    `
  );

  const banner1 = getImage(data.img.childImageSharp.gatsbyImageData);

  return (
    <BannerContainer className="w-full md:px-15 px-5 py-5 relative bg-cover bg-center bg-no-repeat
    flex flex-col md:flex-row justify-evenly z-99 montserrat">
      <div className='flex-1 md:mt-30'>
        <div className="montserrat text-4xl md:text-6xl lg:text-8xl xl:text-9xl text-white text-center font-bold  ">
        Descomplique o seu marketing!
        </div>
        <div className="pt-20 text-center">
          <Link 
          to="/#contact">
          <button className="text-xl rounded-md text-center py-5 px-10 text-sm font-bold bg-accent-500" style={{ color: "#D70754" }}>Entre em contato agora</button>
          </Link>
          
        </div>
      </div>
      <div className='flex-1 flex items-center'>
        <GatsbyImage className='md:h-4/5 xl:h-full' image={banner1} alt="img" />
      </div>
      <img className="absolute right-0 top-0 w-full -z-1" src={bannerBack} alt="banner Back" />
      <div className="h-2 w-32"></div>
    </BannerContainer>
    
  );
};

import React from 'react';
import wppSVG from '../../../images/svg/wpp.svg';

const Contact = () => {
	return (
		<div id='contact' className='text-white text-center md:p-10 p-5 pb-10 mt-20' style={{ backgroundColor: 'rgb(132, 53, 136)' }}>
			<div className='max-w-xl mx-auto'>
				<div className='text-6xl mb-5'>
					Leve sua empresa para o próximo nível. <br /> Vamos conversar?
				</div>
				<div className='flex items-center justify-center'>
					<a 
						href='https://api.whatsapp.com/send?phone=5548988682332'
						target='_blank' 
						rel="noreferrer">
						<div className='relative flex flex-col md:flex-row gap-5 items-center justify-center border-2 rounded-lg border-warn px-15 py-10'>
							<img className='w-24' src={wppSVG} alt="wpp" />
							<div className='text-left font-medium'>
								<div className='font-bold'>Atendimento via Whatsapp</div>
								<div>Envie mensagem para</div>
								<div>(48) 98868-2332</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Contact;
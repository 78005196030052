import React from "react";
import service1 from "../../../images/services/service-1.png"
import service2 from "../../../images/services/service-2.png"
import service3 from "../../../images/services/service-3.png"
import service4 from "../../../images/services/service-4.png"
import service5 from "../../../images/services/service-5.png"
import service6 from "../../../images/services/service-6.png"
import service7 from "../../../images/services/service-7.png"
import service8 from "../../../images/services/service-8.png"

const Services = () => {
    return (
        <div id="services" className="container mx-auto">
            <div className="text-4xl md:text-6xl font-extrabold text-center pt-10 pb-20" style={{ color: "rgb(132, 53, 136)" }}>
                Principais Serviços
            </div>
            <div className="mx-4 md:mx-8 flex items-center justify-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-24 m-4 sm:m-8 lg:m-10">
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service1}
                        alt="service-1"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Gestão de Redes Sociais
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service2}
                        alt="service-2"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Produção de imagens
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service3}
                        alt="service-3"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Tráfego Pago
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service4}
                        alt="service-4"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Produção de Sites
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service5}
                        alt="service-5"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Consultoria de Redes Sociais
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service6}
                        alt="service-6"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Criação de Identidade Visual
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service7}
                        alt="service-7"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Peças Gráficas Gerais
                        </div>
                    </div>
                    <div className="w-full h-full rounded-2xl px-8 pt-8 pb-4 relative" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
                        <img
                        className="absolute w-28 h-28 -top-1/2 left-1/2 transform translate-y-0 -translate-x-1/2 w-28 h-28 bg-white rounded-full shadow-2xl "
                        src={service8}
                        alt="service-8"
                        />
                        <div className="px-5 text-2xl text-white font-semibold text-center pt-10 md:pt-5">
                            Demais serviços Offline
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services
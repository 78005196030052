import React from 'react'
import logo2 from '../../../images/svg/logo2.svg'

const Footer = () => {
  return (
    <footer className="flex md:flex-row md:justify-evenly gap-y-5 flex-col justify-center items-center p-10 px-3 z-99999" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
      <div className="px-4 text-center md:text-left">
        <img className="w-32" src={logo2} alt="logo" />
      </div>
      <div className="px-4 text-center md:text-left">
        <h3 className="text-xl font-bold text-gray-800">
          <span className="text-white">ACOMPANHE</span>
        </h3>
        <ul className='text-center md:text-left'>
          <li className="text-white py-2">
            <a href="https://instagram.com/mkt.lotus" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer


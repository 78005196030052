import React from 'react'
import wpp from '../../../images/svg/wpp.svg'

const Whatsapp = () => {
  return (
    <a href="https://api.whatsapp.com/send?phone=5548988682332" 
    target="_blank" 
    rel="noreferrer"
    className="fixed bottom-10 right-10 bg-green-500 rounded-full h-15 w-15 p-3 hvr-grow border-2 border-white shadow-md z-99">
      <img className='w-full' src={wpp} alt="" />
    </a>
  )
}

export default Whatsapp

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import check from "../../../images/svg/check.svg";


const WhyChoose = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "avatar-contract.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    `
  );

  const avatar = getImage(data.img.childImageSharp.gatsbyImageData);

  return (
  <div id="whychoose" className="relative">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="text-center pt-10">
        <div className="text-4xl md:text-6xl lg:text-8xl font-extrabold" style={{ color: "rgb(132, 53, 136)" }}>
          Por que contratar <br/> a Lótus?
        </div>
        <div className=" text-center">
          <GatsbyImage image={avatar} alt="img" />
        </div>
      </div>
      
      <div className="text-white pl-2 md:pl-10 md:my-10 md:py-10 md:rounded-tl-xl md:rounded-bl-xl" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
        <ul className="list-none items-left z-10 my-5 md:my-10 md:py-10" style={{paddingLeft: 0}}>
          <li>
            <div className="flex flex-row">
              <img
                className="mr-0 hidden md:block md:mr-5"
                src={check}
                alt="check"
              />
              <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                Aumentar a visibilidade da sua marca
              </div>
            </div>
          </li>
          <li>
            <div className="flex flex-row">
              <img
                className="mr-0 hidden md:block md:mr-5"
                src={check}
                alt="check"
              />
              <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                Aumentar seu engajamento
              </div>
            </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Presença Online e Offline                    
                  </div>
              </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Ações Comerciais                   
                  </div>
              </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Criatividade nas postagens
                  </div>
              </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Perfil mais convidativo
                  </div>
              </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Destaque no mercado
                  </div>
              </div>
          </li>
          <li>
              <div className="flex flex-row">
                  <img
                      className="mr-0 hidden md:block md:mr-5"
                      src={check}
                      alt="check"
                  />
                  <div className="text-xl md:text-2xl lg:text-4xl xl:text-6xl font-bold text-left">
                  Vendas para seu negócio
                  </div>
              </div>
          </li>
        </ul>
      </div>
      

    </div>
  </div>
 );
};

export default WhyChoose 
import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import logo from '../../../images/svg/logo2.svg';



const Header = ({ path, children, fixed }) => {

  return (
    <>
      <Helmet>
        <title>Lótus</title>
        {/* font */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet"></link>
      </Helmet>

      <nav  className={`py-2 md:py-2 z-99999 ${fixed ? 'fixed top-0 left-0 right-0' : ''}`} style={{ backgroundColor: 'rgb(132, 53, 136)' }}>
        <div className="container px-4 mx-auto justify-between flex md:items-center">

          <div className="flex justify-between items-center">
            <Link to='/' className="font-bold text-xl text-indigo-600">
              <img className="w-40 h-24" src={logo} alt="Lótus logo" />
            </Link>
          </div>

          <div className="flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
            <Link
              to="/#whychoose"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-700 transition-colors duration-300">
              QUEM SOMOS
            </Link>
            <Link
              to="/#services"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-700 transition-colors duration-300">
              SERVIÇOS
            </Link>
            <Link
              to="/#portfolio"
              className="hidden md:inline-block p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg hover:bg-accent-700 transition-colors duration-300">
              CLIENTES
            </Link>
            <Link
              to="#contact"
              className="p-2 cursor-pointer lg:px-4 md:mx-2 text-white font-semibold rounded-lg border border-accent hover:bg-accent-700 transition-colors duration-300">
              CONTATO
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header

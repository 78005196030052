import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const Portfolio = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "banner-portfolio.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    `
  );

  const avatar = getImage(data.img.childImageSharp.gatsbyImageData);

  return (
  <div id="portfolio" className="relative py-10">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="text-white pl-2 md:pl-10 md:my-10 md:py-10 md:rounded-tr-xl md:rounded-br-xl" style={{ backgroundColor: "rgb(132, 53, 136)" }}>
        <div className=" text-center rounded-xl">
          <GatsbyImage className="rounded-xl" image={avatar} alt="img" />
        </div>          
        <div className="text-center text-4xl font-extrabold">
            {"< >"}
          </div>
      </div>
      <div className="text-center md:text-right items-right md:justify-right md:p-30">
        <div className="text-4xl lg:text-8xl font-extrabold" style={{ color: "rgb(132, 53, 136)" }}>
        Conheça um pouco dos nossos serviços e clientes.
        </div>
        <div className="pt-0 md:pt-20">
          <a href="https://www.behance.net/lotusmktdigital" 
            target="_blank" 
            rel="noreferrer">
            <button className="text-base md:text-lg lg:text-xl rounded-md text-center m-10 py-3 px-4 md:py-5 md:px-10 text-sm font-bold bg-accent-500" style={{ color: "#D70754" }}>ACESSE NOSSO PORTFOLIO COMPLETO</button>
          </a>
        </div>
      </div>
    </div>
  </div>
 );
};

export default Portfolio 
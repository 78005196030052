import React from 'react';
import Layout from '../components/Layout';
import Banner from '../components/Home/Banner';
import WhyChoose from '../components/Home/WhyChoose';
import Services from '../components/Home/Services';
import Portfolio from '../components/Home/Portfolio';
import Work from '../components/Home/Work';
import Contact from '../components/Home/Contact'

const Index = () => {
  return (
    <Layout>
      <Banner />
      <WhyChoose />
      <Services />
      <Portfolio/>
      <Work />
      <Contact />
    </Layout>
    /* 29/05 */
  )
}

export default Index
import React from 'react';
import Slider from 'react-slick';
import { BannerDois } from './BannerDois';

const Banner = () => {
  
  const settings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    dots: false,
    dotsClass: 'slick-dots z-9999'
  };

  return (

    <Slider {...settings}>
      <div>
        <BannerDois />
      </div>
    </Slider >
  )
}

export default Banner
import React from "react";
import footerImage from "../../../images/svg/background-image.svg";

const Work = () => {

  return (
    <div className="w-full relative bg-cover bg-center bg-no-repeat
    flex flex-col md:flex-row z-99 montserrat bg-warn-500">
      <img className="hidden md:block absolute w-full -z-1" src={footerImage} alt="banner Back" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 ">
        <div className="hidden md:block">
        </div>
        <div className="text-center md:text-right m-2">
          <div className="montserrat text-6xl md:text-4xl lg:text-7xl xl:text-9xl text-white font-bold ">
            Vamos trabalhar juntos?
          </div>
          <div className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl text-white">
            Será um prazer ter você no nosso time.
          </div>
          <div className="pt-0 md:pt-20">

          <a href="https://api.whatsapp.com/send?phone=5548988682332" 
                target="_blank" 
                rel="noreferrer">
            <button className="text-base md:text-lg lg:text-xl rounded-md text-center m-10 py-3 px-4 md:py-5 md:px-10 text-sm font-bold bg-accent-500" style={{ color: "#D70754" }}>Entre em contato agora mesmo</button>
            </a>
          </div>
        </div>
      </div>

    </div>
 );
};

export default Work; 
